import React, { useState } from "react";
import bannerimgo5 from "../Assets/images/Subbanner/05 Online Inquiry.jpg"
import "./OnlineInqury.css";
import {
  Tab,
  Tabs,
  Row,
  Col,
  InputGroup,
  Button,
  Container,
  Form,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

const OnlineInqury = () => {
  const [key, setKey] = useState("fcl");

  return (
    
    <div>
           
           <div style={{ position: "relative", marginTop: "160px" }}>
        <img
          src={bannerimgo5}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px", objectFit: "cover" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
          ONLINE ENQUIRY
        </h1>
      </div>
      <Container className="text-center mt-5">
      {/* Center the buttons */}
      <Row className="justify-content-center mb-4">
        <Col md="auto">
          <InputGroup>
            <Button
              variant={key === "fcl" ? "primary" : "outline-primary"}
              className="mx-1 shadow-sm"
              onClick={() => setKey("fcl")}
            >
              <i className="bi bi-box"></i> FCL
            </Button>
            <Button
              variant={key === "lcl" ? "primary" : "outline-primary"}
              className="mx-1 shadow-sm"
              onClick={() => setKey("lcl")}
            >
              <i className="bi bi-box"></i> LCL
            </Button>
            <Button
              variant={key === "air" ? "primary" : "outline-primary"}
              className="mx-1 mshadow-s"
              onClick={() => setKey("air")}
            >
              <i className="bi bi-airplane"></i> AIR
            </Button>
          </InputGroup>
        </Col>
      </Row>

      {/* Tabs for content */}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="text-start mb-4"
      >
        {/* FCL Tab */}
        <Tab eventKey="fcl" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">FCL Inquiry</h4>
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text" placeholder="Origin Port..." />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Port..."
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="containerType">
                    <Form.Label>Container Type*</Form.Label>
                    <Form.Select>
                      <option>Select container type</option>
                      <option>20 ft</option>
                      <option>40 ft</option>
                      <option>Reefer</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="quantity">
                    <Form.Label>Qty*</Form.Label>
                    <Form.Control type="number" defaultValue={10} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Rice, Spices..."
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select>
                      <option>Select time frame</option>
                      <option>Immediate</option>
                      <option>1-2 weeks</option>
                      <option>3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select>
                      <option>CFR: Cost And Freight</option>
                      <option>FOB: Free On Board</option>
                      <option>CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"
                        defaultChecked
                        className="me-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="stuffingType">
                    <Form.Label>Stuffing Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Dock Stuffing"
                        name="stuffingType"
                        defaultChecked
                        className="me-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Factory Stuffing"
                        name="stuffingType"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name" />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Your phone number"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="text-center mt-4">
                <Button variant="primary" type="submit" className="px-5 py-2">
                  Request Quote
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>

        {/* LCL Tab */}
        <Tab eventKey="lcl" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">LCL Inquiry</h4>
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text" placeholder="Origin Port..." />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Port..."
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Mangoes, Pulses..."
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="packages">
                    <Form.Label>No. Of Packages*</Form.Label>
                    <Form.Control type="number" defaultValue={10} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Dimensions Per Package*</Form.Label>
                  <InputGroup>
                    <Form.Control type="number" placeholder="L" />
                    <Form.Control type="number" placeholder="W" />
                    <Form.Control type="number" placeholder="H" />
                  </InputGroup>
                  <Form.Text className="text-muted">In centimeters</Form.Text>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="weight">
                    <Form.Label>Weight Per Package*</Form.Label>
                    <Form.Control type="number" placeholder="Kgs" />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select>
                      <option>Select time frame</option>
                      <option>Immediate</option>
                      <option>1-2 weeks</option>
                      <option>3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select>
                      <option>CFR: Cost And Freight</option>
                      <option>FOB: Free On Board</option>
                      <option>CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"
                        defaultChecked
                        className="me-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name" />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Your phone number"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="text-center mt-4">
                <Button variant="primary" type="submit" className="px-5 py-2">
                  Request Quote
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>

        {/* AIR Tab */}
        <Tab eventKey="air" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">AIR Inquiry</h4>
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text" placeholder="Origin Airport..." />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Airport..."
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Electronics, Garments..."
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="pieces">
                    <Form.Label>No. Of Pieces*</Form.Label>
                    <Form.Control type="number" defaultValue={10} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Dimensions Per Piece*</Form.Label>
                  <InputGroup>
                    <Form.Control type="number" placeholder="L" />
                    <Form.Control type="number" placeholder="W" />
                    <Form.Control type="number" placeholder="H" />
                  </InputGroup>
                  <Form.Text className="text-muted">In centimeters</Form.Text>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="weight">
                    <Form.Label>Weight Per Piece*</Form.Label>
                    <Form.Control type="number" placeholder="Kgs" />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select>
                      <option>Select time frame</option>
                      <option>Immediate</option>
                      <option>1-2 weeks</option>
                      <option>3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select>
                      <option>CFR: Cost And Freight</option>
                      <option>FOB: Free On Board</option>
                      <option>CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"
                        defaultChecked
                        className="me-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name" />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Your phone number"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="text-center mt-4">
                <Button variant="primary" type="submit" className="px-5 py-2">
                  Request Quote
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>
      </Tabs>
    </Container>
    </div>
  );
};

export default OnlineInqury;
