import "bootstrap/dist/css/bootstrap.min.css";
import bannerimg from "../Assets/images/Subbanner/06 Contact Us.jpg";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useState } from "react";
import icon1 from "../Assets/images/icons/location.svg";
import icon2 from "../Assets/images/icons/mail.svg";
import icon3 from "../Assets/images/icons/phone.svg";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation
    const newErrors = {};
    if (!formData.fullname) newErrors.fullname = "Full name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle form submission, e.g., send data to an API
      console.log("Form Data Submitted:", formData);
    }
  };

  return (
    <div>
      {/* Banner Section */}
      <div style={{ position: "relative", marginTop: "160px" }}>
        <img
          src={bannerimg}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
          Contact Us
        </h1>
      </div>

      {/* Form Section */}
      <section className="py-3 py-md-5 py-xl-8">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
              <h2 className="mb-4 display-5 text-center">Help Desk</h2>
              <p className="text-secondary mb-5 text-center lead fs-4">
                Our team is available to provide prompt and helpful responses to
                all inquiries. You can reach us via phone, email, or by filling
                out the contact form below.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
          <Col
  xs={12}
  lg={6}
  className="bsg-overlay background-position-center background-size-cover"
>
  <div className="contact-info-wrapper py-4 py-xl-5">
    <h2 className="h1 mb-3 text-dark">Get in touch</h2>
    <p className="lead fs-4 text-dark opacity-75 mb-4 mb-xxl-5">
      We're always on the lookout to work with new clients. If you're interested in working with us, please get in touch in one of the following ways.
    </p>

    {/* Address Section */}
    <div className="d-flex align-items-start mb-4 mb-xxl-5">
      <div className="me-3 text-primary d-flex align-items-start">
        <img src={icon1} alt="Address Icon" width="32" height="32" />
      </div>
      <div>
        <h4 className="mb-2 text-dark">Address</h4>
        <address className="mb-0 text-dark opacity-75">
          OFFICE No: B1102, LATIFA TOWER. SHEIKH ZAYED ROAD. DUBAI, UAE
        </address>
      </div>
    </div>

    {/* Phone and Email Section */}
    <div className="row mb-3 mb-xxl-5">
      {/* Phone Section */}
      <Col xs={12} xxl={5}>
        <div className="d-flex align-items-start mb-4 mb-xxl-0">
          <div className="me-3 text-primary d-flex align-items-start">
            <img src={icon3} alt="Phone Icon" width="32" height="32" />
          </div>
          <div>
            <h4 className="mb-2 text-dark">Phone</h4>
            <a href="tel:+971545403036" className="text-dark opacity-75 text-decoration-none">
              +971 54 54 03 03 6
            </a>
          </div>
        </div>
      </Col>

      {/* Email Section */}
      <Col xs={12} xxl={5}>
        <div className="d-flex align-items-start mb-4 mb-xxl-0">
          <div className="me-3 text-primary d-flex align-items-start">
            <img src={icon2} alt="Email Icon" width="32" height="32" />
          </div>
          <div>
            <h4 className="mb-2 text-dark">Email</h4>
            <a href="mailto:info@yourcompany.com" className="text-dark opacity-75 text-decoration-none">
              info@emarsaleglobalshipping.com
            </a>
          </div>
        </div>
      </Col>
    </div>
  </div>
</Col>

            <Col xs={12} lg={6}>
              <div className="contact-form-wrapper bg-light p-4 p-md-5 rounded-3 shadow-lg">
                <h4 className="mb-3">Contact Us</h4>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="mb-3" controlId="formFullName">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="fullname"
                          value={formData.fullname}
                          onChange={handleChange}
                          isInvalid={!!errors.fullname}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fullname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    
                  </Row>
                  <Row>
                    
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Your message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-grid">
                    <Button type="submit" variant="primary" size="lg">
                      Send Message
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ContactForm;
