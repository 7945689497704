import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
 
const JobApplication = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { job } = location.state || {};

  if (!job) {
    return <div>No job selected</div>;
  }
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Here you can handle the form submission logic (e.g., send data to server)

    // Redirect to the home page
    navigate('/');
  };

  return (
    <Container className="py-5">
      <br /><br /> <br /> <br />
      <Row className="justify-content-center">
        {/* Left side: Job details */}
        <Col md={6} className="mb-4">
          <Card className="shadow-sm border-0 rounded">
            <Card.Body>
              <Card.Title as="h3" className="text-primary">{job.title}</Card.Title>
              <Card.Text>
                <strong>Description:</strong> {job.description}
              </Card.Text>
              <Card.Text>
                <strong>Qualifications:</strong> {job.qualifications}
              </Card.Text>
              <Card.Text>
                <strong>Experience:</strong> {job.Experiences}
              </Card.Text>
              <Card.Text>
                <strong>Application Date:</strong> {job.applyDate}
              </Card.Text>
              <Card.Text>
                <strong>Job Location :</strong> {job.Place}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Right side: Application form */}
        <Col md={6}>
          <Card className="shadow-sm border-0 rounded">
            <Card.Body>
              <Card.Title as="h4" className="text-primary">Apply for this position</Card.Title>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" required/>
                </Form.Group>
                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" required />
                </Form.Group>
                <Form.Group controlId="formResume" className="mt-3">
                  <Form.Label>Resume</Form.Label>
                  <Form.Control type="file" required/>
                </Form.Group>
                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Write a message" required />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3" onClick={handleSubmit}>
                  Submit Application
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default JobApplication;
