import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bannerimg04 from "../Assets/images/Subbanner/04 Careers.jpg";

const Careers = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    window.addEventListener("load", () => {
      AOS.refresh();
    });
  });

  const navigate = useNavigate();

  const jobOpenings = [
    {
      id: 1,
      title: "Documentation Executive",
      description:
        "The ideal candidate will have a strong background in Sea Freight operations, customs clearance, and extensive knowledge of Dubai Trade,Stack Operations and contacts within shipping lines. The role requires a detail-oriented individual with excellent organizational and communication skills to ensure smooth and efficient freight forwarding operations.",
      qualifications: `
           Education: Bachelor’s degree or diploma in logistics and supply chain management.`,
          Experiences: ` Experience: Minimum of 1 year of experience in a freight forwarding company.
           Strong knowledge of freight forwarding operations and logistics processes.
           Excellent organizational and multitasking skills.
          Proficiency in freight management software and Microsoft Office Suite.
           Strong communication skills, both written and verbal.
           Ability to work effectively under pressure and meet tight deadlines.
           Attention to detail, particularly in documentation and data entry.`,
      applyDate: " 25/09/2024",
      Place: "Dubai",
    },
    {
      id: 2,
      title: "Operations Executive",
      description: `  This position involves coordinating shipments, ensuring timely delivery, and maintaining
                     compliance with all relevant regulations. The ideal candidate will possess strong organizational skills, attention to
                     detail, and a deep understanding of the logistics industry,`,
                     qualifications: `
                     Education: Bachelor’s degree or diploma in logistics and supply chain management. `,
                   
                     Experiences : ` Minimum of 1 year of experience in a freight forwarding company.
                     Strong knowledge of freight forwarding operations and logistics processes.
                     Excellent organizational and multitasking skills.
                     Proficiency in freight management software and Microsoft Office Suite.
                     Strong communication skills, both written and verbal.
                     Ability to work effectively under pressure and meet tight deadlines.
                     Attention to detail, particularly in documentation and data entry.`,
                     applyDate: "25/09/2024",
                    Place: "Dubai",
                    // endDate: "2024-10-05",
    },
    {
      id: 3,
      title: "Sales Executive ",
      description:
       ` We are looking for a dynamic and results-oriented Sales Executive to join our team. This role involves driving
         revenue growth, building and maintaining client relationships, and identifying new business opportunities. The ideal
         candidate will possess strong sales acumen, excellent communication skills, and a proven track record of meeting or
         exceeding sales targets.`,
      qualifications:
                    " Bachelor’s degree in Business, Marketing, or a related field",
      Experiences : `: Minimum of 1 years of experience in freight forwarding company.
                       Demonstrated ability to drive sales and achieve targets
                       Ability to identify & convert new business opportunities
                       Excellent communication, negotiation, and interpersonal skills
                       Build and maintain strong, long-lasting customer relationships.
                       Provide timely feedback and reports to management on sales performance
                       Handle negotiations and finalize agreements to close sales.
                      `,             

        applyDate: "25/09/2024",
        Place: "Dubai",
      // endDate: "2024-10-10",
    },
  ];

  const handleApply = (job) => {
    navigate(`/apply/${job.id}`, { state: { job } });
  };
  

  return (
    <div>
      <div style={{ position: "relative", marginTop: "140px" }}>
        <img
          src={bannerimg04}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px", objectFit: "cover" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
          CAREERS
        </h1>
      </div>
      <Container className="py-5">
        <h2 className="text-center mb-5" data-aos="fade-up">
          Career Opportunities
        </h2>
        <Row>
          {jobOpenings.map((job) => (
            <Col key={job.id} md={4} className="mb-4">
              <Card
                data-aos="fade-up"
                style={{
                  border: "none",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                }}
              >
                <Card.Body>
                  <Card.Title
                    className="text-primary"
                    style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                  >
                    {job.title}
                  </Card.Title>
                  <Card.Text
                    style={{ minHeight: "100px", marginBottom: "1.5rem", fontSize:"1.0rem" }}
                  >
                    {job.description}
                  </Card.Text>
                  <Button variant="primary" onClick={() => handleApply(job)}>
                    Apply Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Careers;
